import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  updateReady: boolean
}

const initialState: State = {
  updateReady: false,
}

class AutoUpdateStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "UPDATE_READY":
        return this.handleUpdateReady()
      default:
        return false
    }
  }

  private handleUpdateReady() {
    this.setState({updateReady: true})
  }
}

export default new AutoUpdateStore()
